import React, { useState, useRef } from 'react';
import './Hero.css';
import { useTranslation } from 'react-i18next';

const Hero: React.FC = () => {
  const { t } = useTranslation();
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  // Handle background click to focus input
  const handleBackgroundClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  // Handle key press
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && input.trim()) {
      setMessages([...messages, input.trim()]);
      setInput(''); // Clear the input field after posting the message
    }
  };

  return (
    <section className="hero" onClick={handleBackgroundClick}>
      <h1>{t('home_header')}</h1>
      <p>{t('home_body')}</p>
      <button style={{ zIndex: "10" }} onClick={() => window.scrollTo({ top: 2000, behavior: 'smooth' })}>
        {t('home_button')}
      </button>

  

      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </section>
  );
};

export default Hero;

import React, { useState, useEffect } from 'react';
import './CursorPosition.css';

const CursorPosition: React.FC = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHoveringInteractive, setIsHoveringInteractive] = useState(false);

  const updatePosition = (event: MouseEvent) => {
    setPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseEnter = () => {
    setIsHoveringInteractive(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringInteractive(false);
  };

  useEffect(() => {
    window.addEventListener('mousemove', updatePosition);

    const interactiveElements = document.querySelectorAll('button, a');

    interactiveElements.forEach((element) => {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
    });

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', updatePosition);
      interactiveElements.forEach((element) => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  return (
    <>
      {!isHoveringInteractive && (
        <>
          <div
            className="cursor-x"
            style={{
              left: `${position.x}px`,
              top: `${position.y}px`,
            }}
          >
            X
          </div>
          <div
            className="cursor-position"
            style={{
              left: `${position.x + 15}px`, // Slight offset from the "X"
              top: `${position.y + 15}px`,
            }}
          >
            X: {position.x}, Y: {position.y}
          </div>
        </>
      )}
    </>
  );
};

export default CursorPosition;

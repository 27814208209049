// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "about_us": "About 6c6.no",
        "welcome": "Welcome to 6c6.no, where AI meets modern web development.",
        "intro": "Since 2010, 6c6.no has evolved from a tech research hub to a digital agency focused on enhancing your website’s performance. Rebranded in 2022, we now specialize in combining technology and design to deliver better customer experiences.",
        "why_choose": "Why Choose 6c6.no?",
        "ai_driven": "AI-Driven Insights",
        "ai_driven_text": "We use AI to analyze, rank, and optimize your website, offering deep insights and identifying improvement opportunities.",
        "reports": "Comprehensive Webpage Reports",
        "reports_text": "Our Webpage Report provides a free analysis that ranks your site against industry standards and suggests actionable improvements.",
        "development": "Smart Website Development",
        "development_text": "Whether you need a full rebuild or minor tweaks, we tailor solutions that integrate smoothly with your existing setup.",
        "seo_focus": "SEO and Google Ranking Focus",
        "seo_focus_text": "We focus on improving your Google rankings with strategies that increase visibility through on-page and technical SEO.",
        "tailored_goals": "Tailored Goals and Strategies",
        "tailored_goals_text": "We customize goals and a clear roadmap based on your site analysis, whether it’s UX enhancement, conversion increase, or a new build.",
        "projects_header": "My Projects",
        "contact_header": "Contact Me",
        "home_header": "Welcome to 6c6",
        "home_body": "Where analog research meets digital technology",
        "home_button": "Explore Projects",
        "nav_home": "Home",
        "nav_about": "About",
        "nav_projects": "Projects",
        "nav_contact": "Contact",
      },
    },
    fr: {
      translation: {
        "about_us": "À propos de 6c6.no",
        "welcome": "Bienvenue chez 6c6.no, où l'IA rencontre le développement web moderne.",
        "intro": "Depuis 2010, 6c6.no est passé d'un centre de recherche technologique à une agence numérique axée sur l'amélioration des performances de votre site web. Rebrandé en 2022, nous nous spécialisons désormais dans la combinaison de la technologie et du design pour offrir de meilleures expériences clients.",
        "why_choose": "Pourquoi choisir 6c6.no?",
        "ai_driven": "Informations pilotées par l'IA",
        "ai_driven_text": "Nous utilisons l'IA pour analyser, classer et optimiser votre site web, en offrant des informations approfondies et en identifiant les opportunités d'amélioration.",
        "reports": "Rapports complets de pages Web",
        "reports_text": "Notre rapport de page web fournit une analyse gratuite qui classe votre site par rapport aux normes de l'industrie et suggère des améliorations concrètes.",
        "development": "Développement intelligent de sites Web",
        "development_text": "Que vous ayez besoin d'une refonte complète ou de quelques ajustements, nous adaptons les solutions pour s'intégrer harmonieusement à votre configuration existante.",
        "seo_focus": "Focus sur le référencement et le classement Google",
        "seo_focus_text": "Nous nous concentrons sur l'amélioration de votre classement Google grâce à des stratégies qui augmentent la visibilité via le référencement sur page et technique.",
        "tailored_goals": "Objectifs et stratégies personnalisés",
        "tailored_goals_text": "Nous personnalisons des objectifs et une feuille de route claire en fonction de l'analyse de votre site, qu'il s'agisse d'améliorer l'expérience utilisateur, d'augmenter les conversions ou de créer un nouveau site.",
        "projects_header": "Mes Projets",
        "contact_header": "Contactez-moi",
        "home_header": "Bienvenue chez 6c6",
        "home_body": "Où la recherche analogique rencontre la technologie numérique",
        "home_button": "Explorer les Projets",
        "nav_home": "Accueil",
        "nav_about": "À propos",
        "nav_projects": "Projets",
        "nav_contact": "Contact",
      },
    },
    no: {
      translation: {
        "about_us": "Om 6c6.no",
        "welcome": "Velkommen til 6c6.no, hvor AI møter moderne webutvikling.",
        "intro": "Siden 2010 har 6c6.no utviklet seg fra et teknologisk forskningssenter til et digitalt byrå som fokuserer på å forbedre ytelsen til nettstedet ditt. Rebrandet i 2022, vi spesialiserer oss nå på å kombinere teknologi og design for å levere bedre kundeopplevelser.",
        "why_choose": "Hvorfor velge 6c6.no?",
        "ai_driven": "AI-drevne innsikter",
        "ai_driven_text": "Vi bruker AI til å analysere, rangere og optimalisere nettstedet ditt, og tilbyr dype innsikter og identifiserer forbedringsmuligheter.",
        "reports": "Omfattende nettsiderapporter",
        "reports_text": "Vår nettsiderapport gir en gratis analyse som rangerer nettstedet ditt mot industristandarder og foreslår konkrete forbedringer.",
        "development": "Smart nettstedsutvikling",
        "development_text": "Enten du trenger en fullstendig ombygging eller mindre justeringer, tilpasser vi løsninger som integreres sømløst med din eksisterende oppsett.",
        "seo_focus": "Fokus på SEO og Google-rangering",
        "seo_focus_text": "Vi fokuserer på å forbedre din Google-rangering med strategier som øker synligheten gjennom on-page og teknisk SEO.",
        "tailored_goals": "Skreddersydde mål og strategier",
        "tailored_goals_text": "Vi skreddersyr mål og en klar veikart basert på analyse av nettstedet ditt, enten det er å forbedre brukeropplevelsen, øke konverteringene eller bygge en ny side.",
        "projects_header": "Mine Prosjekter",
        "contact_header": "Kontakt Meg",
        "home_header": "Velkommen til 6c6",
        "home_body": "Hvor analog forskning møter digital teknologi",
        "home_button": "Utforsk Prosjekter",
        "nav_home": "Hjem",
        "nav_about": "Om",
        "nav_projects": "Prosjekter",
        "nav_contact": "Kontakt",
      },
    },
    sv: {
      translation: {
        "about_us": "Om 6c6.no",
        "welcome": "Välkommen till 6c6.no, där AI möter modern webbutveckling.",
        "intro": "Sedan 2010 har 6c6.no utvecklats från ett teknologiskt forskningscenter till en digital byrå med fokus på att förbättra din webbplats prestanda. Omprofilerat 2022, vi specialiserar oss nu på att kombinera teknik och design för att leverera bättre kundupplevelser.",
        "why_choose": "Varför välja 6c6.no?",
        "ai_driven": "AI-drivna insikter",
        "ai_driven_text": "Vi använder AI för att analysera, ranka och optimera din webbplats, och erbjuder djupa insikter och identifierar förbättringsmöjligheter.",
        "reports": "Omfattande webbsiderapporter",
        "reports_text": "Vår webbsiderapport ger en gratis analys som rankar din webbplats mot branschstandarder och föreslår konkreta förbättringar.",
        "development": "Smart webbplatsutveckling",
        "development_text": "Oavsett om du behöver en fullständig ombyggnad eller mindre justeringar, skräddarsyr vi lösningar som integreras sömlöst med din befintliga installation.",
        "seo_focus": "Fokus på SEO och Google-rankning",
        "seo_focus_text": "Vi fokuserar på att förbättra din Google-rankning med strategier som ökar synligheten genom on-page och teknisk SEO.",
        "tailored_goals": "Anpassade mål och strategier",
        "tailored_goals_text": "Vi skräddarsyr mål och en tydlig färdplan baserat på din webbplatsanalys, oavsett om det handlar om att förbättra användarupplevelsen, öka konverteringar eller bygga en ny sida.",
        "projects_header": "Mina Projekt",
        "contact_header": "Kontakta Mig",
        "home_header": "Välkommen till 6c6",
        "home_body": "Där analog forskning möter digital teknik",
        "home_button": "Utforska Projekt",
        "nav_home": "Hem",
        "nav_about": "Om",
        "nav_projects": "Projekt",
        "nav_contact": "Kontakt",
      },
    },
    da: {
      translation: {
        "about_us": "Om 6c6.no",
        "welcome": "Velkommen til 6c6.no, hvor AI møder moderne webudvikling.",
        "intro": "Siden 2010 har 6c6.no udviklet sig fra et teknologisk forskningscenter til et digitalt bureau med fokus på at forbedre din hjemmesides ydeevne. Rebrandet i 2022, vi specialiserer os nu i at kombinere teknologi og design for at levere bedre kundeoplevelser.",
        "why_choose": "Hvorfor vælge 6c6.no?",
        "ai_driven": "AI-drevne indsigter",
        "ai_driven_text": "Vi bruger AI til at analysere, rangere og optimere din hjemmeside, og tilbyder dybe indsigter og identificerer forbedringsmuligheder.",
        "reports": "Omfattende hjemmeside-rapporter",
        "reports_text": "Vores hjemmeside-rapport giver en gratis analyse, der rangerer din hjemmeside mod industristandarder og foreslår konkrete forbedringer.",
        "development": "Smart hjemmesideudvikling",
        "development_text": "Uanset om du har brug for en fuld ombygning eller mindre justeringer, skræddersyr vi løsninger, der integreres problemfrit med din eksisterende opsætning.",
        "seo_focus": "Fokus på SEO og Google-ranking",
        "seo_focus_text": "Vi fokuserer på at forbedre din Google-ranking med strategier, der øger synligheden gennem on-page og teknisk SEO.",
        "tailored_goals": "Skræddersyede mål og strategier",
        "tailored_goals_text": "Vi skræddersyr mål og en klar køreplan baseret på din hjemmesideanalyse, uanset om det handler om at forbedre brugeroplevelsen, øge konverteringer eller bygge en ny side.",
        "projects_header": "Mine Projekter",
        "contact_header": "Kontakt Mig",
        "home_header": "Velkommen til 6c6",
        "home_body": "Hvor analog forskning møder digital teknologi",
        "home_button": "Udforsk Projekter",
        "nav_home": "Hjem",
        "nav_about": "Om",
        "nav_projects": "Projekter",
        "nav_contact": "Kontakt",
      },
    },
    fi: {
      translation: {
        "about_us": "Tietoa 6c6.no:sta",
        "welcome": "Tervetuloa 6c6.no:lle, missä tekoäly kohtaa modernin web-kehityksen.",
        "intro": "Vuodesta 2010 lähtien 6c6.no on kehittynyt teknologiakeskuksesta digitaaliseksi toimistoksi, joka keskittyy verkkosivustosi suorituskyvyn parantamiseen. Uudelleenbrändätty vuonna 2022, erikoistumme nyt yhdistämään teknologian ja suunnittelun paremman asiakaskokemuksen tarjoamiseksi.",
        "why_choose": "Miksi valita 6c6.no?",
        "ai_driven": "AI-pohjaiset oivallukset",
        "ai_driven_text": "Käytämme tekoälyä analysoimaan, arvioimaan ja optimoimaan verkkosivustoasi tarjoamalla syvällisiä oivalluksia ja tunnistamalla parannusmahdollisuuksia.",
        "reports": "Kattavat verkkosivuraportit",
        "reports_text": "Verkkosivuraporttimme tarjoaa ilmaisen analyysin, joka arvioi sivustosi teollisuusstandardien mukaisesti ja ehdottaa konkreettisia parannuksia.",
        "development": "Älykäs verkkosivujen kehitys",
        "development_text": "Tarvitsetpa täydellisen uudelleenrakentamisen tai pieniä säätöjä, räätälöimme ratkaisut, jotka integroituvat saumattomasti nykyiseen järjestelmääsi.",
        "seo_focus": "SEO ja Google-sijoituksen parantaminen",
        "seo_focus_text": "Keskitymme parantamaan Google-sijoitustasi strategioilla, jotka lisäävät näkyvyyttä on-page- ja teknisen SEO:n kautta.",
        "tailored_goals": "Räätälöidyt tavoitteet ja strategiat",
        "tailored_goals_text": "Räätälöimme tavoitteet ja selkeän etenemissuunnitelman verkkosivustosi analyysin perusteella, olipa kyseessä käyttäjäkokemuksen parantaminen, konversioiden lisääminen tai uuden sivuston rakentaminen.",
        "projects_header": "Omat projektit",
        "contact_header": "Ota yhteyttä",
        "home_header": "Tervetuloa 6c6",
        "home_body": "Missä analoginen tutkimus kohtaa digitaalisen teknologian",
        "home_button": "Tutustu projekteihin",
        "nav_home": "Koti",
        "nav_about": "Tietoa",
        "nav_projects": "Projektit",
        "nav_contact": "Ota yhteyttä",
      },
    },
  },
  lng: 'no', // default language
  fallbackLng: 'en', // fallback language if a key is missing
  interpolation: {
    escapeValue: false, // react already handles xss protection
  },
});

export default i18n;

// src/components/Modal.tsx
import React from 'react';
import './Modal.css';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  url: string;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, url }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        {url ? (
          <iframe src={url} title="Project Preview" className="modal-iframe"></iframe>
        ) : (
          <p>No URL provided.</p>
        )}
      </div>
    </div>
  );
};

export default Modal;

// src/components/ProjectCard.tsx
import React from 'react';
import './Projects.css';

interface ProjectCardProps {
  id: string;
  title: string;
  description: string;
  url?: string;
  onClick?: () => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ title, description, onClick }) => {
  return (
    <div className="project-card" onClick={onClick}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default ProjectCard;

// src/components/Footer.tsx
import React from 'react';
import './Footer.css';
import LanguageSwitcher from './LanguageSwitcher';

const Footer: React.FC = () => {
  return (
    <div style={{height:"20%",backgroundColor:"black",display:"flex",flexDirection:"column",alignItems:"center"}}>
      <div className="social-links">
        {/* Add animated social media icons here */}
      </div>
      <LanguageSwitcher/>
      <p style={{color:"white"}}>6c6.no 2024</p>
     
    </div>
  );
};

export default Footer;

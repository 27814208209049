import React from 'react';
import './About.css';
import { useTranslation } from 'react-i18next';
import {
  FaAws, FaPython, FaReact, FaNodeJs, FaDocker, FaGithub,
} from 'react-icons/fa';
import { SiAzuredevops, SiJavascript, SiKubernetes, SiGoogleanalytics, SiWordpress, SiCsharp,SiTypescript,SiFirebase } from 'react-icons/si';
// Import SVG images
import PowerVirtualAgents from '../images/PowerVirtualAgents_scalable.svg';
import AIBuilder from '../images/AIBuilder_scalable.svg';
import Dataverse from '../images/Dataverse_scalable.svg';
import PowerApps from '../images/PowerApps_scalable.svg';
import PowerAutomate from '../images/PowerAutomate_scalable.svg';
import PowerBI from '../images/PowerBI_scalable.svg';
import PowerFx from '../images/PowerFx_scalable.svg';
import PowerPages from '../images/PowerPages_scalable.svg';
import PowerPlatform from '../images/PowerPlatform_scalable.svg';

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section id="about-us">

      <div className="about-content">
        <div className="column">
          <h3>{t('ai_driven')}</h3>
          <p>{t('ai_driven_text')}</p>
          <div className="icon-row">
            <FaPython className="tech-icon" title="Python" />
            <SiAzuredevops className="tech-icon" title="Azure DevOps" />
          </div>

          <h3>{t('reports')}</h3>
          <p>{t('reports_text')}</p>
          <div className="icon-row">
           
            <FaGithub className="tech-icon" title="GitHub" />
            <FaNodeJs className="tech-icon" title="Node.js" />
            <FaReact className="tech-icon" title="React" />
            <SiJavascript className="tech-icon" title="JavaScript" />
            <SiFirebase className="tech-icon" title="Firebase" />
            <SiCsharp className="tech-icon" title="C#" />
          </div>
        </div>

        <div className="column">
          <h3>{t('development')}</h3>
          <p>{t('development_text')}</p>
          <div className="icon-row">
            <FaAws className="tech-icon" title="AWS" />
            <FaDocker className="tech-icon" title="Docker" />
            <SiKubernetes className="tech-icon" title="Kubernetes" />
            <SiAzuredevops className="tech-icon" title="Azure DevOps" />
            <FaNodeJs className="tech-icon" title="Node.js" />
            <FaReact className="tech-icon" title="React" />
            <SiJavascript className="tech-icon" title="JavaScript" />
            <SiTypescript className='tech-icon' title='Typescirpt'/>
          </div>

          <h3>{t('seo_focus')}</h3>
          <p>{t('seo_focus_text')}</p>
          <div className="icon-row">
            <SiGoogleanalytics className="tech-icon" title="Google Analytics" />
            <SiWordpress className="tech-icon" title="WordPress" />
          </div>

          <h3>{t('tailored_goals')}</h3>
          <p>{t('tailored_goals_text')}</p>

          {/* Power Platform Section */}
          <div className="icon-row">
            <img src={PowerPlatform} alt="Power Platform" className="tech-icon" />
            <img src={PowerApps} alt="Power Apps" className="tech-icon" />
            <img src={PowerAutomate} alt="Power Automate" className="tech-icon" />
            <img src={PowerBI} alt="Power BI" className="tech-icon" />
            <img src={PowerVirtualAgents} alt="Power Virtual Agents" className="tech-icon" />
            <img src={PowerPages} alt="Power Pages" className="tech-icon" />
            <img src={PowerFx} alt="Power Fx" className="tech-icon" />
            <img src={AIBuilder} alt="AI Builder" className="tech-icon" />
            <img src={Dataverse} alt="Dataverse" className="tech-icon" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

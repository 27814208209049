import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import Modal from './Modal';
import './Projects.css';
import { useTranslation } from 'react-i18next';

interface Repository {
  id: number;
  name: string;
  description: string;
  html_url: string;
  homepage: string | null; // Allow homepage to be string or null
}

const Projects: React.FC = () => {
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [selectedUrl, setSelectedUrl] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchRepositories = async () => {
      try {
        const response = await fetch('https://api.github.com/users/6c6-dev/repos');
        const data = await response.json();

        if (Array.isArray(data)) {
          // Filter out repositories without homepage directly from the GitHub API data
          const filteredData: Repository[] = data.filter((repo: Repository) => repo.homepage !== null);
          
          setRepositories(filteredData);
          console.log('Repositories fetched:', filteredData);
        } else {
          console.error('Expected an array of repositories:', data);
        }
      } catch (error) {
        console.error('Error fetching repositories:', error);
      }
    };

    fetchRepositories();
  }, []);

  const openModal = (url: string | null) => {
    if (url) {
      setSelectedUrl(url);
    } else {
      console.warn('No valid URL to open in the modal.');
    }
  };

  const closeModal = () => {
    setSelectedUrl(null);
  };

  return (
    <section className="projects">
      <h2>{t('projects_header')}</h2>
      <div className="project-list">
        {repositories.map((repo) => (
          <ProjectCard
            key={repo.id}
            id={repo.id.toString()}
            title={repo.name}
            description={repo.description || 'No description provided'}
            url={repo.homepage!} // homepage is now guaranteed to be a string
            onClick={() => openModal(repo.homepage!)} // Use homepage only
          />
        ))}
      </div>
      {selectedUrl && <Modal show={!!selectedUrl} onClose={closeModal} url={selectedUrl} />}
    </section>
  );
};

export default Projects;

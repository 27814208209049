import React from 'react';
import './App.css';
import Hero from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Testimonials from './components/Testimonials';
import ContactForm from './components/ContactForm';
import Footer from './components/Footer';
import LanguageSwitcher from './components/LanguageSwitcher';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import Cour from './components/Cour';
const App: React.FC = () => {
  const { t } = useTranslation();
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="App">
        <header>
          <nav className="navbar">
            <ul>
              <li><a href="#hero">{t('nav_home')}</a></li>
              <li><a href="#about">{t('nav_about')}</a></li>
              <li><a href="#projects">{t('nav_projects')}</a></li>
              
              <li><a href="#contact">{t('nav_contact')}</a></li>
            </ul>
            
          </nav>
        </header>

        <div id="hero"><Hero /></div>
        <div id="about"><About /></div>
        <div id="projects"><Projects /></div>
       
        <div id="contact"><ContactForm /></div>
        <Footer />
        <Cour/>
      </div>
    </DndProvider>
  );
};

export default App;

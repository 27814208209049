import React, { useState } from 'react';
import './ContactForm.css';
import { useTranslation } from 'react-i18next';
const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const { t } = useTranslation();
  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setErrors({ ...errors, email: 'Please enter a valid email address.' });
      } else {
        setErrors({ ...errors, email: '' });
      }
    }

    if (e.target.name === 'name' && e.target.value.trim() === '') {
      setErrors({ ...errors, name: 'Name is required.' });
    } else if (e.target.name === 'name') {
      setErrors({ ...errors, name: '' });
    }

    if (e.target.name === 'message' && e.target.value.trim() === '') {
      setErrors({ ...errors, message: 'Message is required.' });
    } else if (e.target.name === 'message') {
      setErrors({ ...errors, message: '' });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Basic validation before submission
    if (!formData.name || !formData.email || !formData.subject || !formData.message) {
      alert('Please fill out all fields correctly before submitting.');
      return;
    }
    if (errors.name || errors.email || errors.message) {
      alert('Please correct the errors before submitting.');
      return;
    }

    console.log(formData);
    // Clear the form after submission
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
    alert('Your message has been sent!');
  };

  return (
    <section className="contact-form">
      <h2>{t('contact_header')}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            className={errors.name ? 'error' : ''}
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>

        <div className="form-group">
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            className={errors.email ? 'error' : ''}
          />
          {errors.email && <span className="error-message">{errors.email}</span>}
        </div>

        <div className="form-group">
          <select
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            className={errors.subject ? 'error' : ''}
          >
            <option value="" disabled>
              Select a Subject
            </option>
            <option value="General Inquiry">General Inquiry</option>
            <option value="Support">Support</option>
            <option value="Feedback">Feedback</option>
            <option value="Other">Other</option>
          </select>
          {errors.subject && <span className="error-message">{errors.subject}</span>}
        </div>

        <div className="form-group">
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            className={errors.message ? 'error' : ''}
          ></textarea>
          {errors.message && <span className="error-message">{errors.message}</span>}
        </div>

        <button type="submit">Send Message</button>
      </form>
    </section>
  );
};

export default ContactForm;
